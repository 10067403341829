var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-sidebar',{ref:"sidebarLine",attrs:{"id":"sidebar-line","aria-labelledby":"sidebar-line-header","right":"","backdrop":"","shadow":"","no-header":"","width":"85vw"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between shadow align-items-center"},[_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"text-light rounded-pill",attrs:{"block":"","variant":"dark"},on:{"click":_vm.hide}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"text-body rounded-pill btn-main",attrs:{"block":"","variant":"light"},on:{"click":function($event){$event.preventDefault();return _vm.submitLine.apply(null, arguments)}}},[_vm._v(" Save ")])],1)])]},proxy:true}]),model:{value:(_vm.isShowSidebar),callback:function ($$v) {_vm.isShowSidebar=$$v},expression:"isShowSidebar"}},[_c('div',{staticClass:"px-3"},[_c('b-row',{staticClass:"header-filter"},[_c('b-col',{staticClass:"text-filter",attrs:{"cols":"6"}},[_vm._v("Line")]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('button',{staticClass:"btn-clear",on:{"click":function($event){$event.preventDefault();return _vm.hide.apply(null, arguments)}}},[_vm._v("x")])])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[(_vm.form[_vm.form.findIndex((el) => el.communication_type_id == 3)])?_c('InputText',{attrs:{"textFloat":"Preview Text","placeholder":"Preview Text","type":"text","name":"preview-text"},model:{value:(
              _vm.form[_vm.form.findIndex((el) => el.communication_type_id == 3)]
                .line_preview_text
            ),callback:function ($$v) {_vm.$set(_vm.form[_vm.form.findIndex((el) => el.communication_type_id == 3)]
                , "line_preview_text", $$v)},expression:"\n              form[form.findIndex((el) => el.communication_type_id == 3)]\n                .line_preview_text\n            "}}):_vm._e()],1)],1),_c('LineMessageTypeComponent',{ref:"LineMessage",attrs:{"messageType":_vm.messageType,"list":_vm.form,"formMain":_vm.formMain,"v":_vm.v,"isShowSidebar":_vm.isShowSidebar,"dataImage":_vm.dataImage,"id":_vm.id,"variableItem":_vm.typeLine === 'segment' && _vm.formMain.segmentationType === 2
            ? _vm.variableItemSegment
            : _vm.variableItem}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }