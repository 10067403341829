<template>
  <div>
    <b-sidebar
      id="sidebar-sms"
      ref="sidebarSMS"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-sms-header"
      right
      backdrop
      shadow
      no-header
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Consent Status</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <b-form-group label="Consent Status" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="consent-group-1"
              v-model="consent"
              :options="consentList"
              :aria-describedby="ariaDescribedby"
              stacked
              name="consent-options"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              class="text-body rounded-pill btn-main"
              @click.prevent="submit"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    isConsent: {
      required: false,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      consentList: [
        { text: "Consent", value: 0 },
        { text: "Not Consent", value: 2 },
        { text: "All", value: 1 },
      ],
      consent: 0,
    };
  },
  watch: {},
  methods: {
    show() {
      this.consent = this.isConsent;
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },

    submit() {
      this.$emit("setConsent", this.consent);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.title-msg {
  color: #000;
  font-size: 17px;
}
.btn-outline {
  background-color: var(--theme-secondary-color);
  border-radius: 50px;
  color: var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
